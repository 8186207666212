// @import "animate.css/animate.min.css";
@import "aos/dist/aos.css";

// for debugging
* {
  // outline: 1px solid #f00;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Tajawal", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // min-height: 4000px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

section {
  padding-block: 3rem;
}

.navbar-padding {
  padding-top: 150px;
}

// Overrides
a,
a:hover,
a:focus,
a:visited,
a:active {
  text-decoration: none !important;
  color: inherit;
  text-decoration-style: none !important;
}

//  RTL overrides
html[lang="ar"] {
  .MuiPagination-ul {
    li:first-of-type,
    li:last-of-type {
      button {
        transform: rotate(180deg);
      }
    }
  }

  .MuiPickersArrowSwitcher-button {
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 600px) {
  .footer-container-box {
    flex-direction: column !important;
    // align-items: flex-start !important;
  }
}
